
body {
  background-color: rgb(233, 222, 222);
}

.search-container {
  padding-top: 100px;
  position: relative;
  padding-bottom: 20px;
  padding-left: 38%;
}

.search-input {
  font-size: 25px;
  font-family: monospace;
  margin-left: auto;
  margin-right: auto;
  border: 30px solid black;
  transition: 180ms ease-in-out;
}

.search-input:focus {
  outline: 3px solid transparent;
}

.search-label {
  font-size: medium;
  font-family: monospace;
}

